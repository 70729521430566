/* GENERAL */
* {
  font-family: "Lato", sans-serif;
  color: #505050;
}
body {
  background-color: #f9fcff !important;
}
button:focus {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
/* FONT STUFF  */
/* ///// colors ////// */
.text-pyrlple {
  color: #5762c2 !important;
}
.text-rxgblue {
  color: #0865d1 !important;
}
.text-gray {
  color: #505050 !important;
}
.text-gray-light {
  color: #807f7f !important;
}
.text-bluegray {
  color: #4b5066 !important;
}
/* ///// sizes ////// */
.text-sm {
  font-size: 0.87rem;
}
.text-x-sm {
  font-size: 0.77rem;
}
/* ///// weights ////// */
.bold {
  font-weight: 700;
}
.boldest {
  font-weight: 900;
}
.light {
  font-weight: 300;
}
/* //// letter spacing /// */
.some-space {
  letter-spacing: 2px;
}
/* //// line-height /// */
.more-line-height {
  line-height: 1.42;
}
/* //// hrs //// */
.light-hr {
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

.avatar {
  background-image: -webkit-linear-gradient(
    170deg,
    rgba(215, 144, 250) 0%,
    rgba(129, 172, 253) 100%
  );
  background-image: linear-gradient(
    -80deg,
    rgba(215, 144, 250) 0%,
    rgba(129, 172, 253) 100%
  );
  width: 100px;
  height: 100px;
  border-radius: 287px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white !important;
}

.grad-btn {
  background-image: -webkit-gradient(linear, left top, right top, from(#82acfd), to(#d392fa));
  background-image: -webkit-linear-gradient(left, #82acfd 0%, #d392fa 100%);
  background-image: linear-gradient(90deg, #82acfd 0%, #d392fa 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(#6297ff), to(#cd76ff));
  background-image: -webkit-linear-gradient(left, #6297ff 0%, #cd76ff 100%);
  background-image: linear-gradient(90deg, #6297ff 0%, #cd76ff 100%);
  border: none;
  border-radius: 27px;
  padding: 7px 50px;
  font-size: 17px;
  color: white !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.grad-btn:hover {
  position: relative;
  bottom: 1px;
}
.gradback {
  background-image: -webkit-linear-gradient(
    170deg,
    rgba(215, 144, 250, 0.058) 0%,
    rgba(129, 172, 253, 0.076) 100%
  );
  background-image: linear-gradient(
    -80deg,
    rgba(215, 144, 250, 0.058) 0%,
    rgba(129, 172, 253, 0.076) 100%
  );
}
.waves {
  width: 100%;
  /* margin-left: -2%;
  margin-right: -2%; */
  margin-bottom: -2%;
}

.opa-hover:hover {
  opacity: 0.87;
}
.dum {
  color: rgb(173, 173, 173);
  font-size: 27px;
}

.quiz-menu-arrow {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.quiz-menu-button {
  border: none;
  background-color: white;
}
.quiz-menu {
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);

  padding: 12px 24px;
  border-radius: 27px;
  background-color: white;
  margin-bottom: 27px;
}
.menu-box {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.progress-bar {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

/* HOME */
.option-card {
  background-color: #5397fd;
  /* width: 150px;
  height: 150px; */
  color: white !important;
  font-weight: 700;
  width: 200px;
  max-width: 90vw;
  border-radius: 42px;
  /* transition: 0.2s; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.option-card:hover {
  position: relative;
  bottom: 3px;
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
}
.quiz-option {
  width: 200px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.mech-option-card {
  background-color: rgb(255, 255, 255);
  background-color: #5397fd;
  /* color: #4b5066 !important; */
  color: white;

  font-weight: 400;
  font-size: 11pt;
  width: 200px;
  max-width: 90vw;
  border-radius: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.mech-option-card:hover {
  position: relative;
  bottom: 3px;
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
}
.prodmain {
  color: white;
  font-size: 50px;
}
.product-card {
  background-color: #5397fd;
  width: 150px;
  height: 150px;
  max-width: 90vw;
  border-radius: 42px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.product-card:hover {
  position: relative;
  bottom: 3px;
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
}
.product-card-subtext {
  color: #807f7f;
  text-align: center;
  /* width: 150px;
  max-width: 90vw; */
  margin: 12px 0 33px 0;
}
.product-card-img {
  height: 70px;
}
.white-card {
  background-color: white;
  width: 150px;
  height: 150px;
  max-width: 90vw;
  border-radius: 7px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.qbtn {
  width: 300px;
  font-weight: 700;
  padding: 7px;
  border-radius: 7px;
  max-width: 80vw;
  text-align: center;
  background-color: rgba(135, 206, 250, 0.1);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.qbtn:hover {
  cursor: pointer;
  background-color: rgba(135, 206, 250, 0.486);
}
/* EXAMS */
.exam-box {
  background-color: white;
  width: 800px;
  max-width: 90vw;
  border-radius: 7px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  padding: 12px;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

/* QUIZZES */
.quiz-background {
  background-color: white;
  min-height: 100vh;
}
.quiz-header {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  position: relative;
}
.quiz-container {
  max-width: 570px;
  background-color: white;
  min-height: 100vh;
  /* box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64); */
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.quiz-exit {
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 12px !important;

  border-radius: 7px;
  padding: 3px 12px;
  color: white;
  background-color: #2c8fff;
  border: none;
  -webkit-transition: 0.17s;
  transition: 0.17s;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

/* SPINNERS */
.main-loading-spinner-container {
  position: fixed;
  top: calc(50% - 28px);
  left: calc(50% - 28px);
}
.loading-spinner-1 {
  height: 27px;
  display: none;
}

/* ACCOUNT */
#match-passwords {
  visibility: hidden;
}

.account-container {
  border-radius: 27px;
  padding: 10px 17px 12px 17px;
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
}
.simple-flexbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.simple-flexbox-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.account-btn {
  text-align: center;
}
.content-container-4 {
  border-radius: 27px;
  padding: 7px 17px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 17px !important;
  border: solid 1px rgba(128, 128, 128, 0);
  width: 187px;
  box-shadow: 0 12px 22px 0 rgba(228, 228, 234, 0.56),
    0 5px 18px 0 rgba(228, 228, 234, 0.64);
}
.content-container-4:hover {
  border: solid 1px rgba(128, 128, 128, 0.17);
}
.content-container-5 {
  border-radius: 7px;
  padding: 7px 17px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 27px !important;
  border: solid 1px rgba(128, 128, 128, 0);
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
}
.content-container-5:hover {
  border: solid 1px rgba(128, 128, 128, 0.17);
}
#delete-account-box {
  display: none;
}

/* FORMS */
.input-1 {
  border-radius: 27px;
  padding: 7px 17px;
  height: 42px;
  border: solid 1px rgba(128, 128, 128, 0);
  border: solid 1px rgba(128, 128, 128, 0.17);
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
  -webkit-transition: 0.17s;
  transition: 0.17s;
  width: 77%;
  min-width: 207px;
}
.input-1:hover {
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
  border-color: #dd00ff;
  box-shadow: 0 1px 1px #5761c2 inset, 0 0 1px #5761c2;
}
.input-1:focus {
  border-color: #dd00ff;
  box-shadow: 0 1px 1px #5761c2 inset, 0 0 1px #5761c2;
  outline: 0 none;
}
.input-2 {
  border-radius: 27px;
  padding: 7px 17px;
  height: 42px;
  border: solid 1px rgba(128, 128, 128, 0.17);
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
  -webkit-transition: 0.17s;
  transition: 0.17s;
  width: 100%;
  min-width: 207px;
}
.input-2:hover {
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
  border-color: #dd00ff;
  box-shadow: 0 1px 1px #5761c2 inset, 0 0 1px #5761c2;
}
.input-2:focus {
  border-color: #dd00ff;
  box-shadow: 0 1px 1px #5761c2 inset, 0 0 1px #5761c2;
  outline: 0 none;
}
.select-1 {
  padding: 7px 17px;
  height: 42px;
  border: solid 1px rgba(128, 128, 128, 0.17);
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
}
.submit-btn-1 {
  text-align: center;
  background-color: #5397fd;
  border-radius: 27px;
  color: white;
  width: 50%;
  max-width: 187px;
}
.submit-btn-1:hover {
  border: solid 1px rgba(128, 128, 128, 0.17);
  position: relative;
  bottom: 1px;
  opacity: 0.93;
}
.red-btn-1 {
  text-align: center;
  background-color: #dc3545;
  color: white;
}
.red-btn-1:hover {
  border: solid 1px rgba(128, 128, 128, 0.17);
  position: relative;
  bottom: 1px;
}

.spacer-block {
  height: 100px;
}
/* AUTH */
.auth-logo {
  cursor: pointer;
  width: 77%;
}
.auth-logo:hover {
  opacity: 0.87;
}
.auth-background {
  /* background-image: linear-gradient(180deg, #6769e6 0%, #ac10fb 100%); */
  /* background-color: #4285f4; */
  background-image: -webkit-linear-gradient(
    170deg,
    rgba(215, 144, 250) 0%,
    rgba(129, 172, 253) 100%
  );
  background-image: linear-gradient(
    -80deg,
    rgba(215, 144, 250) 0%,
    rgba(129, 172, 253) 100%
  );

  min-height: 100vh;
}
.auth-container {
  border-radius: 7px;
  background-color: white;
  padding: 10px 17px 12px 17px;
  box-shadow: 0 6px 12px 0 rgba(109, 109, 109, 0.27),
    0 4px 8px 0 rgba(107, 107, 107, 0.27);
}
.input-3 {
  border-radius: 27px;
  padding: 7px 17px;
  height: 50px;
  border: solid 1px rgba(128, 128, 128, 0.17);
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
  -webkit-transition: 0.17s;
  transition: 0.17s;
  width: 100%;
  min-width: 207px;
}
.input-3:hover {
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
  border-color: rgba(129, 172, 253) 100%;
  box-shadow: 0 1px 1px rgba(129, 172, 253) inset, 0 0 1px rgba(129, 172, 253);
}
.input-3:focus {
  border-color: rgba(129, 172, 253) 100%;
  box-shadow: 0 1px 1px rgba(129, 172, 253) inset, 0 0 1px rgba(129, 172, 253);
  outline: 0 none;
}

/* SUBSCRIBE */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
#subscribe-pyrl-container {
  text-align: center;
  width: 207px;
  -webkit-animation-name: pulse;
          animation-name: pulse;
  -webkit-animation-duration: 1.57s;
          animation-duration: 1.57s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
#subscribe-pyrl-container:hover,
#subscribe-pyrl-container:focus {
  -webkit-animation-name: unset;
          animation-name: unset;
  opacity: 0.87;
}

/* DRUG LABELS */
.drug-label {
  font-size: 0.77rem;
  color: White;
  padding: 0.27rem 0.5rem;
  border-radius: 17px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  margin: 0 0 5px 5px;
}
.drug-labels-container {
  text-align: right;
}
.blue-1 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#36bdf5), to(#3673f5));
  background-image: -webkit-linear-gradient(top, #36bdf5 0%, #3673f5 100%);
  background-image: linear-gradient(180deg, #36bdf5 0%, #3673f5 100%);
}
.red-1 {
  background-image: -webkit-linear-gradient(45deg, #ff005d 0%, #ff2259 100%);
  background-image: linear-gradient(45deg, #ff005d 0%, #ff2259 100%);
}
.red-2 {
  background-image: -webkit-linear-gradient(45deg, #ff005d 0%, #db1345 100%);
  background-image: linear-gradient(45deg, #ff005d 0%, #db1345 100%);
}
.tan-1 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fcd181), to(#e79087));
  background-image: -webkit-linear-gradient(top, #fcd181 0%, #e79087 100%);
  background-image: linear-gradient(-180deg, #fcd181 0%, #e79087 100%);
}
.tan-2 {
  background-image: -webkit-gradient(
    linear,
    left bottom, left top,
    color-stop(12%, rgb(255, 155, 24)),
    color-stop(96.8%, rgb(255, 219, 89))
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgb(255, 155, 24) 12%,
    rgb(255, 219, 89) 96.8%
  );
  background-image: linear-gradient(
    360deg,
    rgb(255, 155, 24) 12%,
    rgb(255, 219, 89) 96.8%
  );
}
/* DRUG TITLE */
.drug-nav {
  display: flex;
  flex-direction: row;
}
.drug-nav-btn-container {
  width: 25%;
  text-align: center;
}
.drug-nav-btn {
  height: 27px;
  border: none;
  padding: 0;
  background-color: #c0c0c0;
}
.drug-nav-btn.active {
  background-color: #7a86f0;
}
.drug-nav-btn-icon {
  height: 27px;
}
.drug-nav-btn-icon.active {
  background-color: #7a86f0;
}

#pyrly-slider {
  -webkit-transition: 0.17s;
  transition: 0.17s;
  height: 3px;
  width: 0px;
  background-image: -webkit-gradient(linear, right top, left top, from(#6050fb), to(#e967ff));
  background-image: -webkit-linear-gradient(right, #6050fb 0%, #e967ff 100%);
  background-image: linear-gradient(-90deg, #6050fb 0%, #e967ff 100%);
}
.drug-title-text-container {
  display: flex;
  flex-direction: row;
}
#drug-content-nav {
  display: none;
}

/* PYRL */
.content-container-1 {
  border-radius: 17px;
  padding: 10px 17px 12px 17px;
}
.content-container-1:hover {
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
}
.pyrl-text-main-container {
  display: flex;
  flex-direction: row;
}
.pyrl-btn-shell {
  width: calc(100% - 15px);
  border: none;
  background: none;
  padding: 0;
}
.pyrl-favorite-shell {
  margin-left: 7px;
  margin-right: 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.pyrl-text-container {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  text-align: left;
  padding-left: 7px;
}
.pyrl-label-container {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
  margin-top: 5px;
}
.pyrl-label {
  height: 15px;
  margin-right: 5px;
}
.refs-link:hover {
  text-decoration: none;
  color: #505050 !important;
}

/* FAVORITES */
.favorite-icon {
  -webkit-transition: 0.12s;
  transition: 0.12s;
  margin: 0px;
  cursor: pointer;
  height: 17px;
  background-clip: content-box;
}
.favorite-icon:hover {
  height: 20px;
}
.favorite-icon.favorited {
  background-color: #7a86f0;
}

/* DOSING */
.content-container-2 {
  border-radius: 27px;
  padding: 7px 17px;
  display: inline-block;
}
.content-container-2:hover {
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
}
.dosing-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dosing-title-btn-shell {
  border: none;
  background: none;
  padding: 0;
}
.dosing-icon {
  height: 33px;
  margin: 0 7px 0 0;
  padding: 0;
}
.indication-btn-shell {
  width: 100%;
  border: none;
  background: none;
  padding: 0;
  /* text-align: center; */
  text-align: left;
}
.left-wing {
  padding-left: 27px !important;
}

/* RESOURCES */
.resource-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.resource-icon {
  height: 33px;
  margin: 0 7px 0 0;
  padding: 0;
}
.resource-btn-shell {
  border: none;
  background: none;
  padding: 0;
}

/* MODALS */
.modal-content {
  border: none;
  border-radius: 17px;
}
.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
}
.modal-footer {
  border: none;
}
.modal-close-btn {
  border-radius: 7px;
  padding: 3px 12px;
  color: white;
  background-color: #5397fd;
  border: none;
  font-size: 14px;
  -webkit-transition: 0.17s;
  transition: 0.17s;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.modal-link-btn {
  border-radius: 7px;
  padding: 3px 12px;
  color: white;
  background-color: #5397fd;
  border: none;
  font-size: 14px;
  -webkit-transition: 0.17s;
  transition: 0.17s;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.modal-link-btn:hover {
  opacity: 0.93;
  position: relative;
  bottom: 1px;
}
.modal-close-btn:hover {
  opacity: 0.93;
  position: relative;
  bottom: 1px;
}

/* CAROUSEL */
.slidy {
  -webkit-transition: 2s;
  transition: 2s;
}
.carousel-inner .carousel-item {
  transition: -webkit-transform 0.17s ease;
  -webkit-transition: -webkit-transform 0.17s ease;
  transition: transform 0.17s ease;
  transition: transform 0.17s ease, -webkit-transform 0.17s ease;
}
.carousel-indicators li {
  border-radius: 27px;
  border: none;
  width: 7px;
  height: 7px;
  background-color: #7a86f0;
}
.car-control {
  font-size: 17px;
  color: #7a86f0;
}

/* LAYOUT */
.side-nav-container {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  box-shadow: inset -1px 0px 3px 0px rgba(112, 112, 112, 0.27);
  background-color: white;
  z-index: 77;
  overflow: hidden;
  width: 170px;
}
.side-nav-container:hover {
  width: 170px;
}
.sidenav-items-container {
  display: flex;
  flex-direction: column;
}
.content-container-3 {
  border-radius: 27px;
  padding: 7px 17px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 7px !important;
  border: solid 1px rgba(128, 128, 128, 0);
  width: 127px;
}
.content-container-3:hover {
  border: solid 1px rgba(128, 128, 128, 0.17);
  box-shadow: 0 22px 32px 0 rgba(228, 228, 234, 0.56),
    0 9px 28px 0 rgba(228, 228, 234, 0.64);
}
#sidenav-logo {
  height: 27px;
  cursor: pointer;
}
#sidenav-logo:hover {
  opacity: 0.87;
}
.nav-item-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.top-nav-container {
  height: 57px;
  margin-left: 307px;
}
.main-content-container {
  margin-left: 307px;
}

/* NAVS */
#nav-pyrl-icon {
  height: 54px;
}
#nav-pyrl {
  position: fixed;
  bottom: 27px;
  right: 17px;
  height: 57px;
  display: none;
  z-index: 7;
  border: none;
  background: none;
  padding: 0;
}
#nav-pyrl.active {
  opacity: 0.7;
}
#sidenav-control-panel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #532ebb;
  opacity: 0.07;
  z-index: 70;
  display: none;
}
#sidenav-control-panel.active {
  display: block;
}
#sidenav.active {
  width: 187px;
}

/* SEARCHBAR */
#search-nav-spot {
  width: 100%;
  min-width: 320px !important;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}
.searchbar-container {
  width: 50%;
  min-width: 320px;
  margin: 10px 0px;
}
.mobile-search-bar {
  width: 100%;
}

@media only screen and (max-width: 380px) {
}
@media only screen and (max-width: 430px) {
  .content-container-1 {
    border-radius: 27px;
    padding: 11px 7px;
  }
  .searchbar-container {
    margin: 3px 0px;
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .spacer-block {
    height: 0px;
  }
  /* DRUG TITLE */
  .drug-title-container {
    text-align: center;
  }
  .drug-labels-container {
    text-align: center;
  }
  .drug-label {
    font-size: 0.67rem;
  }
  .drug-title-text-container {
    justify-content: center;
  }
  /* END DRUG TITLE */
  .top-nav-container {
    height: 30px;
  }
  /* END LAYOUT */
  /* ACCOUNT */
  .simple-flexbox {
    flex-direction: column;
    text-align: center;
  }
  .simple-flexbox-2 {
    align-items: center;
  }
}
@media only screen and (max-width: 770px) {
  /* LAYOUT */
  .side-nav-container {
    width: 0px;
  }
  .side-nav-container:hover {
    width: 187px;
  }
  .main-content-container {
    margin-left: 0px !important;
  }
  .top-nav-container {
    margin-left: 0px !important;
  }
  /* NAVS */
  #nav-pyrl {
    display: block;
  }
  /* ENDNAVS */
  .left-wing {
    padding-left: 0px !important;
  }
}
@media only screen and (max-width: 991px) {
  #drug-content-nav {
    display: block;
  }
  #dosing-btn-container {
    display: none;
  }
  #resources-btn-container {
    display: none;
  }
  #related-btn-container {
    display: none;
  }
}
@media screen and (max-width: 993px) and (min-width: 771px) {
}

@media only screen and (max-width: 993px) {
  .top-nav-container {
    margin-left: 207px;
  }
  .main-content-container {
    margin-left: 207px;
  }
  .left-wing {
    padding-left: 7px;
  }
}

@media only screen and (max-width: 1299px) and (min-width: 1200px) {
  .top-nav-container {
    margin-left: 227px;
  }
  .main-content-container {
    margin-left: 227px;
  }
  .left-wing {
    padding-left: 7px;
  }
}

@media only screen and (max-width: 1300px) {
  .top-nav-container {
    margin-left: 257px;
  }
  .main-content-container {
    margin-left: 257px;
  }
}

